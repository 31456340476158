import React, { ReactElement } from "react"
import DefaultLayout from "../layouts/default"
import data from "../data/privacy"
import Heading1 from "../components/heading1"
import PrivacyPolicyContainer from "../containers/privacy"

interface Props { }

function PrivacyPage({ }: Props): ReactElement {
  return (
    <div>
      <DefaultLayout title="Privacy Policy">
        <PrivacyPolicyContainer />
      </DefaultLayout>
    </div>
  )
}

export default PrivacyPage
